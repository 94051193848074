<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-app-bar flat scroll-target="#scrolling-techniques" color="white">
          <v-toolbar-title class="font-weight-medium">
            {{ $capitalize($tc("model.integration_integration_page_title")) }}
            <v-btn @click="getNotifications()" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar-title>
        </v-app-bar>
        <v-timeline
          :loading="loading_notifications"
          :dense="$vuetify.breakpoint.smAndDown"
          style="max-width: 900px"
          class="mx-auto"
        >
          <v-timeline-item
            key="server"
            fill-dot
            color="grey darken-3"
            icon="mdi-server-network"
            large
          ></v-timeline-item>
          <v-slide-x-reverse-transition group hide-on-leave>
            <v-timeline-item
              v-for="notification in notifications"
              :key="notification.id"
              :color="getNotificationColor(notification)"
              :icon="getNotificationIcon(notification)"
              fill-dot
              :right="notification.inbound"
              :left="!notification.inbound"
            >
              <v-card
                elevation="4"
                @click="handleEditNotification(notification)"
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon
                          :title="
                            $capitalize(
                              $tc('model.integration_information_submission')
                            )
                          "
                          v-if="notification.inbound"
                          color="red"
                          >mdi-arrow-up</v-icon
                        >
                        <v-icon
                          :title="
                            $capitalize(
                              $tc('model.integration_information_receival')
                            )
                          "
                          v-else
                          color="green"
                          >mdi-arrow-down</v-icon
                        >
                        <span>{{
                          notification.eps_action?.name || notification.action
                        }}</span>
                        <span class="grey--text">
                          #{{ notification.created_at }}</span
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getNotificationExecutedAtLabel(notification) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
          <v-timeline-item
            v-if="limitNotifications"
            large
            fill-dot
            icon="mdi-plus"
            key="new"
          >
            <v-card flat>
              <v-card-text
                :class="{ 'text-right': notifications.lenght % 2 == 1 }"
              >
                <v-btn
                  @click="handleClickShowAllNotifications()"
                  outlined
                  color="primary"
                  :loading="loading_notifications"
                >
                  Mostrar todas
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <v-dialog v-model="edit_notification" max-width="500" persistent>
          <v-card>
            <v-form @submit.prevent="submitNotificationUpdate()">
              <v-card-title class="primary">
                <span class="white--text">{{
                  $capitalize($tc("model.integration_interaction_details"))
                }}</span>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="loading_notification_update"
                  dark
                  @click="edit_notification = false"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col class="text-right">
                    <ScopeProvider scope="leads.notifications.delete">
                      <v-btn
                        @click="deleteNotification(form_edit)"
                        color="red"
                        dark
                        outlined
                        :loading="loading_notification_delete"
                        small
                        >{{
                          $capitalize($tc("model.integration_remove_button"))
                        }}</v-btn
                      >
                    </ScopeProvider>
                  </v-col>
                </v-row>
                <v-text-field
                  :disabled="loading_notification_update"
                  :label="$capitalize($tc('model.integration_action_label'))"
                  v-model="form_edit.action"
                ></v-text-field>
                <v-text-field
                  :disabled="loading_notification_update"
                  :label="$capitalize($tc('model.integration_entity_id_label'))"
                  v-model="form_edit.entity_id"
                ></v-text-field>
                <v-text-field
                  :disabled="loading_notification_update"
                  :label="
                    $capitalize($tc('model.integration_parent_entity_id_label'))
                  "
                  v-model="form_edit.parent_entity_id"
                ></v-text-field>
                <v-text-field
                  :disabled="loading_notification_update"
                  :label="
                    $capitalize($tc('model.integration_justification_label'))
                  "
                  v-model="form_edit.justification"
                ></v-text-field>
                <v-switch
                  :disabled="loading_notification_update"
                  v-model="form_edit.blocked"
                  :label="$capitalize($tc('model.integration_blocked_button'))"
                  color="red"
                ></v-switch>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="loading_notification_update"
                  @click="edit_notification = false"
                  text
                  >{{
                    $capitalize($tc("model.integration_cancel_button"))
                  }}</v-btn
                >
                <ScopeProvider scope="leads.notifications.edit">
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="loading_notification_update"
                  >
                    {{ $capitalize($tc("model.integration_send_button")) }}
                  </v-btn>
                </ScopeProvider>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ScopeProvider from "@/components/ScopeProvider";
import i18n from "@/mixins/i18n";

export default {
  mixins: [i18n],
  name: "integration",
  components: { ScopeProvider },
  data: function () {
    return {
      form: {
        action: "",
        motive: "",
        justification: "",
        attachment: null,
      },
      form_edit: {},
      actions: [
        {
          code: "EXP_SRV_HomeExaminationDateChg",
          label: this.$capitalize(
            this.$tc("model.integration_change_start_date")
          ),
          attachment: false,
          motives: [
            {
              code: "EXP_SRV_BackToStartDtDtMarking",
              label: this.$capitalize(
                this.$tc("model.integration_start_date_after")
              ),
            },
            {
              code: "EXP_SRV_PriorToStrtDtDtMarking",
              label: this.$capitalize(
                this.$tc("model.integration_start_date_prior")
              ),
            },
          ],
        },
        {
          code: "EXP_SRV_AdditionalInfo_Perit",
          label: this.$capitalize(
            this.$tc("model.integration_send_information_analysis")
          ),
          attachment: false,
          motives: [
            {
              code: "EXP_SRV_IdentifOfOtherDamage",
              label: this.$capitalize(
                this.$tc("model.integration_identification_damages")
              ),
            },
            {
              code: "EXP_SRV_Complaint",
              label: this.$capitalize(
                this.$tc("model.integration_reclamation_action")
              ),
            },
            {
              code: "EXP_SRV_ServiceCanceled",
              label: this.$capitalize(
                this.$tc("model.integration_service_request")
              ),
            },
            {
              code: "EXP_SRV_Other",
              label: this.$capitalize(
                this.$tc("model.integration_other_action")
              ),
            },
          ],
        },
        {
          code: "EXP_SRV_ShippingInfProgress",
          label: this.$capitalize(
            this.$tc("model.integration_send_information_intercalate")
          ),
          attachment: false,
          motives: [
            {
              code: "EXP_SRV_FirstInterimInfo",
              label: this.$capitalize(
                this.$tc("model.integration_first_information_intercalate")
              ),
            },
            {
              code: "EXP_SRV_correctedinformatio",
              label: this.$capitalize(
                this.$tc("model.integration_corrected_information")
              ),
            },
            {
              code: "EXP_SRV_NewInterimInformation",
              label: this.$capitalize(
                this.$tc("model.integration_new_information_intercalate")
              ),
            },
            {
              code: "EXP_SRV_MissingDocProcFinish",
              label: this.$capitalize(
                this.$tc("model.integration_missing_documentation")
              ),
            },
          ],
        },
        {
          code: "EXP_SRV_SubmisOfPreliminaryInf",
          label: this.$capitalize(
            this.$tc("model.integration_send_preliminary_information")
          ),
          attachment: true,
          motives: [
            {
              code: "EXP_SRV_SubmitPreliminaryInf",
              label: this.$capitalize(
                this.$tc("model.integration_preliminary_information_send")
              ),
            },
            {
              code: "EXP_SRV_correctedinformation",
              label: this.$capitalize(
                this.$tc("model.integration_corrected_information")
              ),
            },
          ],
        },
        {
          code: "EXP_SRV_SubmissionOfFinalRpt",
          label: this.$capitalize(
            this.$tc("model.integration_send_final_report")
          ),
          attachment: true,
          motives: [
            {
              code: "EXP_SRV_SubmitOfFinalReport",
              label: this.$capitalize(
                this.$tc("model.integration_final_report_send")
              ),
            },
            {
              code: "EXP_SRV_reportfixed",
              label: this.$capitalize(
                this.$tc("model.integration_corrected_report")
              ),
            },
            {
              code: "EXP_SRV_Other",
              label: this.$capitalize(
                this.$tc("model.integration_other_action")
              ),
            },
          ],
        },
        {
          code: "AddAttachment",
          label: this.$capitalize(
            this.$tc("model.integration_insert_attachment")
          ),
          attachment: true,
          motives: [
            {
              code: "AddAttachmentMotive",
              label: this.$capitalize(
                this.$tc("model.integration_insert_attachment")
              ),
            },
          ],
        },
        // {
        //   code: "AddComment",
        //   label: "Inserir Comentário",
        //   motives: [
        //     {
        //       code: "AddCommentMotive",
        //       label: "Inserir Comentário",
        //     },
        //   ],
        // },
        {
          code: "EXP_SRV_FailureAchievementServ",
          label: this.$capitalize(
            this.$tc("model.integration_service_achievement_failure")
          ),
          motives: [
            {
              code: "EXP_SRV_UnsuccAttptsContCust",
              label: this.$capitalize(
                this.$tc("model.integration_unsuccessful_attempts")
              ),
            },
            {
              code: "EXP_SRV_ClientDidNotAttend",
              label: this.$capitalize(
                this.$tc("model.integration_customer_did_not_attend")
              ),
            },
            {
              code: "EXP_SRV_Other",
              label: this.$capitalize(
                this.$tc("model.integration_other_action")
              ),
            },
          ],
        },
        {
          code: "EXP_SRV_RequestAdd_lInf_Perit",
          label: this.$capitalize(
            this.$tc("model.integration_ask_for_information")
          ),
          motives: [
            {
              code: "EXP_SRV_ContactMissing",
              label: this.$capitalize(
                this.$tc("model.integration_missing_contact")
              ),
            },
            {
              code: "EXP_SRV_MissingDocumentation",
              label: this.$capitalize(
                this.$tc("model.integration_missing_documentation")
              ),
            },
            {
              code: "EXP_SRV_Other",
              label: this.$capitalize(
                this.$tc("model.integration_other_action")
              ),
            },
          ],
        },
      ],
      documents: [],
      notifications: [],
      loading_notifications: false,
      new_notification: false,
      edit_notification: false,
      loading_notification_update: false,
      loading_notification_delete: false,
      loading_notification_insert: false,
      poll: null,
      limitNotifications: true,
    };
  },
  methods: {
    async handleClickShowAllNotifications() {
      this.limitNotifications = false;
      this.getNotifications();
    },
    async storeNotification() {
      this.loading_notification_insert = true;
      try {
        const response = await this.$http.post(
          "/leads/" + this.$route.params["lead_number"] + "/eps_notifications",
          this.form
        );
        this.edit_notification = false;

        this.form = {
          action: "",
          motive: "",
          justification: "",
          attachment: null,
        };
        this.new_notification = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          message: this.$capitalize(
            this.$tc("model.integration_failed_register_notification")
          ),
          color: "red",
        });
      } finally {
        this.loading_notification_insert = false;
      }

      this.getNotifications();
    },
    async deleteNotification(notification) {
      if (
        !confirm(
          this.$capitalize(
            this.$tc("model.integration_assurence_delete_notification")
          )
        )
      )
        return;
      this.loading_notification_delete = true;
      try {
        const response = await this.$http.delete(
          "/leads/eps_notifications/" + notification.id
        );
        this.edit_notification = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(
            this.$tc("model.integration_failed_delete_notification")
          ),
          color: "red",
        });
      } finally {
        this.loading_notification_delete = false;
      }

      this.getNotifications();
    },
    async submitNotificationUpdate() {
      this.loading_notification_update = true;
      try {
        const response = await this.$http.put(
          "/leads/eps_notifications/" + this.form_edit.id,
          this.form_edit
        );
        this.edit_notification = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$capitalize(
            this.$tc("model.integration_failed_change_notification")
          ),
          color: "red",
        });
      } finally {
        this.loading_notification_update = false;
      }

      this.getNotifications();
    },
    handleEditNotification(notification) {
      // this.form_edit = { ...notification };
      // this.edit_notification = true;
      this.$router.push(`/eps/notifications/${notification.id}`);
    },
    async getDocumetns() {
      const response = await this.$http.get(
        "/leads/" + this.$route.params["lead_number"] + "/attachments"
      );
      this.documents = response.data;
    },
    async getNotifications() {
      this.loading_notifications = true;
      try {
        const response = await this.$http.get(
          "/leads/" + this.$route.params["lead_number"] + "/eps_notifications",
          {
            params: {
              limit: this.limitNotifications ? 5 : -1,
            },
          }
        );
        this.notifications = response.data.data;
        this.limitNotifications = response.data.meta.last_page > 1;
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.loading_notifications = false;
      }
    },
    getNotificationIcon(notification) {
      if (notification.blocked) return "mdi-alert";
      else if (notification.executed_at) return "mdi-check";
      else if (notification.ignored) return "mdi-pause";
      else return "mdi-timer-sand";
    },
    getNotificationColor(notification) {
      if (notification.blocked) return "red";
      else if (notification.executed_at) return "success";
      else if (notification.ignored) return "grey";
      else return "grey";
    },
    getNotificationExecutedAtLabel(notification) {
      if (notification.blocked) {
        return notification.block_reason;
      } else if (notification.ignored) {
        return this.__(
          "Não será executada até que os problemas anteriores sejam resolvidos.",
          "integration.php"
        );
      } else if (notification.executed_at) {
        return (
          this.__("Executado em ", "integration.php") + notification.executed_at
        );
      } else {
        return this.__("Aguardando integração...", "integration.php");
      }
    },
  },
  computed: {
    motives() {
      if (this.form.action) {
        return this.actions.find((action) => action.code === this.form.action)
          .motives;
      } else {
        return [];
      }
    },
    attachment() {
      if (this.form.action) {
        return this.actions.find((action) => action.code === this.form.action)
          .attachment;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getDocumetns();
    this.getNotifications();
  },
};
</script>
